import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/components/PageLayout.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/components/Sections/Advantages/Advantages.js");
;
import(/* webpackMode: "eager" */ "/app/app/components/Sections/BlockAction/BlockAction.module.scss");
;
import(/* webpackMode: "eager" */ "/app/app/components/Sections/BlockWithIcons/BlockWithIcons.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/components/Sections/BlockWithImage/Slider/Slider.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/components/Sections/ContactsMain/ContactsMain.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/components/Sections/DocumentGroups/DocumentGroups.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/components/Sections/Feedback/Feedback.js");
;
import(/* webpackMode: "eager" */ "/app/app/components/Sections/Head/Head.module.scss");
;
import(/* webpackMode: "eager" */ "/app/app/components/Sections/AwardsDescription/AwardsDescription.module.scss");
;
import(/* webpackMode: "eager" */ "/app/app/components/Sections/AboutCompany/AboutCompany.module.scss");
;
import(/* webpackMode: "eager" */ "/app/app/components/Sections/BlockWithRightImage/BlockWithRightImage.module.scss");
;
import(/* webpackMode: "eager" */ "/app/app/components/Sections/BlockWithTexts/BlockWithTexts.module.scss");
;
import(/* webpackMode: "eager" */ "/app/app/components/Sections/Documents/Documents.module.scss");
;
import(/* webpackMode: "eager" */ "/app/app/components/Sections/Cards/Cards.module.scss");
;
import(/* webpackMode: "eager" */ "/app/app/components/Sections/BlockWithLinks/BlockWithLinks.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/components/Sections/InsuranceProducts/InsuranceProducts.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/components/Sections/LifeContacts/LifeContacts.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/components/Sections/MapWithClinics/MapWithClinics.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/components/Sections/MapWithOffices/MapWithOffices.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/components/Sections/News/News.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/components/Sections/PackagesProducts/PackagesProducts.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/components/Sections/PaginationBySections/PaginationBySections.js");
;
import(/* webpackMode: "eager" */ "/app/app/components/Sections/PartnersCards/PartnersCards.module.scss");
;
import(/* webpackMode: "eager" */ "/app/app/components/Sections/PartnerLogos/PartnerLogos.module.scss");
;
import(/* webpackMode: "eager" */ "/app/app/components/Sections/ProductInfo/ProductInfo.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/components/Sections/RichText/RichText.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/components/Sections/SeoText/SeoText.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/components/Sections/ServiceContent/ServiceContent.js");
;
import(/* webpackMode: "eager" */ "/app/app/components/Sections/ServicesHero/ServicesHero.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/components/Sections/TeamGroups/TeamGroups.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/components/Sections/TeamsSlider/TeamsSlider.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/components/Sections/UsefulLinks/UsefulLinks.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/components/Sections/Vacancies/Vacancies.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/components/Sections/VerticalSlider/Slider/Slider.js");
;
import(/* webpackMode: "eager" */ "/app/app/components/Sections/VerticalSlider/VerticalSlider.module.scss");
;
import(/* webpackMode: "eager" */ "/app/app/components/Sections/InsuranceCases/InsuranceCases.module.scss");
;
import(/* webpackMode: "eager" */ "/app/app/components/Sections/AccordionFiles/AccordionFiles.module.scss");
;
import(/* webpackMode: "eager" */ "/app/app/components/Sections/RecommendServices/RecommendServices.module.scss");
;
import(/* webpackMode: "eager" */ "/app/app/components/Sections/InfoSteps/InfoSteps.module.scss");
;
import(/* webpackMode: "eager" */ "/app/app/components/Sections/Faq/Faq.module.scss");
;
import(/* webpackMode: "eager" */ "/app/app/components/Sections/BlockWithImage/BlockWithImage.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/components/Ui/Accordions/Accordions.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/components/Ui/Breadcrumbs/Breadcrumbs.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/components/Ui/DocumentLink/DocumentLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/components/Ui/Link/Link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/components/Ui/ProductCard/ProductCard.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/components/Ui/Tab/Tab.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/components/Ui/Tabs/Tabs.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
