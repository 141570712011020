'use client'
import isEqual from "lodash/isEqual";
import { useId, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useRouter } from 'next-intl/client'
import { useParams } from 'next/navigation'
import { useForm } from 'react-hook-form'
import { changeImageSrc } from '@/app/utils/changeImageSrc'
import { submitFormBuilder } from '@/app/api/form-builder/apiFormBuilder'
import { CSSTransition } from 'react-transition-group'
import { useTranslations } from 'next-intl'

import FormInput from '@/app/components/Ui/FormInput'
import FormTextarea from '@/app/components/Ui/FormTextarea'
import FormCheckbox from '@/app/components/Ui/FormCheckbox'
import FormSelect from '@/app/components/Ui/FormSelect'
import FormFile from '@/app/components/Ui/FormFile'
import FormInputMask from '@/app/components/Ui/FormInputMask'
import Button from '@/app/components/Ui/Button'
import Icon from '@/app/components/Ui/Icon'

import { getActiveLang } from '@/app/utils/getActiveLang'

import styles from './FormBuilder.module.scss'

const getPattern = (item) => {
  const { regexp } = item

  if (item.type === 'email') {
    return {
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: ''
      }
    }
  }

  if (regexp?.enabled) {
    return {
      pattern: {
        value: RegExp(regexp.pattern, regexp.flags),
        message: ''
      }
    }
  }

  return undefined
}

const getOptions = (options) => {
  return options.map((value) => ({
    value,
    label: value
  }))
}

const pushDataLayer = (data) => {
  if (typeof dataLayer !== 'object' || typeof data !== 'object') {
    return
  }

  dataLayer.push(data);
}

const crmSubmit = async (config) => {
  const arr = []
  const onAjaxSend = (e, req, settings) => {
    arr.push(new Promise((resolve) => {
      $(document).off("ajaxSend", onAjaxSend)
      req.complete(resolve)
    }))
  }

  try {
    $(document).on("ajaxSend", onAjaxSend)

    await landing.createObjectFromLanding(config)
    await Promise.all(arr)
  } catch (error) {
    console.error(error, '-> crmSubmit')
  }
}

// Dev comment: client's method "landing.initLanding(config)" doesn't work as it should. Removed for now.
// const crmInit = async (config) => {
//   console.log(config)
//   const getCondition = () => {
//     return typeof landing === "undefined" && typeof window.jQuery === "undefined"
//   }

//   if (typeof landing === "undefined") {
//     const delay = (ms) => {
//       return new Promise(resolve => {
//         setTimeout(resolve, ms)
//       })
//     }

//     while (getCondition()) {
//       await delay(250)
//     }
//   }

//   landing.initLanding(config)
// }

export default function FormBuilder({
  form,
  className,
  inputsGap,
  isSubmitFullWidth,
  redirect,
  handleAfterSubmit,
}) {
  const t = useTranslations('form')
  const uniqueId = useId()
  const router = useRouter()
  const params = useParams()
  const [isShowSuccess, setIsShowSuccess] = useState(false)
  const { register, handleSubmit, reset, watch, setValue, control, formState: { isSubmitting, errors, isDirty } } = useForm()
  const formData = watch()

  const submitLabel = form?.attributes?.submitButton?.name
  const submitHint = form?.attributes?.submitButton?.hint
  const googleAnalyticsEvent = form?.attributes?.googleAnalytics?.events?.submit
  const inputs = form?.attributes?.settings?.filter((i) => i.active)
  const types = inputs?.reduce((acc, { key, type }) => ({ ...acc, [key]: type }), {})
  const localStorageKey = `form-builder-${form.id}`
  const allTypes = {...types}

  inputs.forEach(i => {
    if (i.isCollapsible) {
      allTypes[`collapsible-${i.key}`] = "boolean";
    }
  })

  const getId = (key) => {
    const formId = form?.id
    return `form-${formId}-${key}`
  }

  const getCrmConfigFields = (obj) => {
    if (!obj) return {}
    const res = {}
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && typeof obj[key] !== "undefined") {
        const type = inputs?.find((i) => i.key === obj[key])?.type
        res[key] = type === "select" ? `#${getId(obj[key])} input[type="hidden"][name="${obj[key]}"]` : `#${getId(obj[key])}`
      }
    }
    return res
  };
  
  const crm = form?.attributes?.crm
  const crmConfig = {
    fields: getCrmConfigFields(crm?.fields),
    contactFields: getCrmConfigFields(crm?.contactFields),
    customFields: {},
    landingId: crm?.landingId || "",
    serviceUrl: crm?.serviceUrl || "",
    redirectUrl: crm?.redirectUrl || "",
    options: {
      disableRefererPolicy: true,
    },
  }

  const afterSubmit = (e) => {
    if (typeof handleAfterSubmit === 'function') {
      handleAfterSubmit(e)
    }
  }

  const onSubmit = async (data) => {
    try {
      const formName = (form?.attributes?.name || '').replaceAll('-', ' ')
      const locale = getActiveLang(params.locale)
      const response = await submitFormBuilder(form.id, {
        ...data,
        pageUrl: window.location.href,
        pageTitle: `${document.title}(${formName})`,
        locale
      }, types)
      if (googleAnalyticsEvent) {
        pushDataLayer({ event: googleAnalyticsEvent })
      }
      if (crm?.enabled) {
        await crmSubmit(crmConfig)
      }
      reset()
      window.localStorage.removeItem(localStorageKey)
      setIsShowSuccess(true)
      setTimeout(() => {
        setIsShowSuccess(false)
      }, 5000)
      if (redirect) {
        router.push(redirect)
      }
      afterSubmit(response)
    } catch (error) {
      console.error(error, '-> submitFormBuilder')
    }
  }

  // useEffect(() => {
  //   if (crm?.enabled) {
  //     crmInit(crmConfig)
  //   }
  // }, [])

  useEffect(() => {
    if (isDirty) {
      const data = {}

      for (const key in allTypes) {
        if (allTypes.hasOwnProperty(key) && typeof allTypes[key] !== "undefined") {
          data[key] = {
            value: allTypes[key] === "file" || (allTypes[key] === "phone" && formData[key] === "+38 (0__) ___ __ __")
              ? undefined
              : formData[key],
            type: allTypes[key]
          }
        }
      }

      window.localStorage.setItem(localStorageKey, JSON.stringify(data))
    }
  }, [formData, isDirty])

  useEffect(() => {
    const item = window.localStorage.getItem(localStorageKey)
    const store = item ? JSON.parse(item) : null
    const storeForm = {}
    const storeTypes = {}

    if (store) {
      for (const key in store) {
        if (store.hasOwnProperty(key) && typeof store[key] !== "undefined") {
          storeForm[key] = store[key].value
          storeTypes[key] = store[key].type
        }
      }
      if (isEqual(storeTypes, allTypes)) {
        for (const key in storeForm) {
          if (storeForm.hasOwnProperty(key) && typeof storeForm[key] !== "undefined") {
            setValue(key, storeForm[key])
          }
        }
      } else {
        window.localStorage.removeItem(localStorageKey)
      }
    }
  }, [setValue])

  if (!Array.isArray(inputs) || inputs.length < 1) return

  return (
    <form
      className={classNames(
        styles['form-builder'],
        className,
        {
          [styles['form-builder--submit-100']]: isSubmitFullWidth,
          [styles['form-builder--success']]: isShowSuccess,
        }
      )}
      onSubmit={handleSubmit(onSubmit)}
      noValidate
    >
      <div
        className={classNames(
          styles['form-builder_inputs'],
          inputsGap && styles[inputsGap],
          'd-f fw-w'
        )}
      >
        {inputs.map((item, index) => (
          <div
            key={item.key}
            className={classNames(
              styles['form-builder_inputs-row'],
              item.isCollapsible && styles['is-collapsible'],
              item.style?.width !== '100' && styles[`width-${item.style.width}`],
            )}
          >
            {item.isCollapsible && (
              <>
                <input
                  {...register(`collapsible-${item.key}`, {
                    required: item.isRequired
                  })}
                  id={`collapsible-${item.key}-${uniqueId}`}
                  className={classNames(
                    styles['collapsible-input'],
                    {
                      'b-error': errors?.[`collapsible-${item.key}`]
                    }
                  )}
                  type="checkbox"
                  role="button"
                />
                <label
                  className={classNames(
                    styles['collapsible-label'],
                  )}
                  htmlFor={`collapsible-${item.key}-${uniqueId}`}
                >
                  {item.collapsibleMsg && typeof item.collapsibleMsg === 'string' ? item.collapsibleMsg : ''}
                </label>
              </>
            )}

            {(item.type === 'text' || item.type === 'email') && (
              <FormInput
                register={register}
                name={item.key}
                exactId={getId(item.key)}
                validation={{
                  required: item.isRequired,
                  minLength: item.minLength,
                  maxLength: item.maxLength,
                  ...getPattern(item),
                }}
                errors={errors}
                placeholder={item.placeholder}
                type={item.type}
              />
            )}

            {item.type === 'number' && (
              <FormInput
                register={register}
                name={item.key}
                exactId={getId(item.key)}
                validation={{
                  required: item.isRequired,
                  minLength: item.minLength,
                  maxLength: item.maxLength,
                  pattern:{
                    value: /^(0|[1-9]\d*)(\.\d+)?$/
                  },
                  onChange: e => {
                    const value = (e.target?.value || '').replace(/\D/g, '')
                    setValue(item.key, value)
                    e.target.value = value
                  },
                  ...getPattern(item),
                }}
                errors={errors}
                placeholder={item.placeholder}
                // type={item.type}
                type={'text'}
              />
            )}

            {item.type === 'textarea' && (
              <FormTextarea
                register={register}
                name={item.key}
                exactId={getId(item.key)}
                validation={{
                  required: item.isRequired,
                  minLength: item.minLength,
                  maxLength: item.maxLength,
                  ...getPattern(item),
                }}
                errors={errors}
                placeholder={item.placeholder}
              />
            )}

            {item.type === 'boolean' && (
              <FormCheckbox
                register={register}
                name={item.key}
                exactId={getId(item.key)}
                validation={{
                  required: item.isRequired
                }}
                errors={errors}
                text={item.placeholder}
              />
            )}

            {item.type === 'select' && (
              <FormSelect
                control={control}
                options={getOptions(item.options)}
                isShowLabel={item.isFastSelect}
                name={item.key}
                exactId={getId(item.key)}
                validation={{
                  required: item.isRequired
                }}
                errors={errors}
                placeholder={item.placeholder}
              />
            )}

            {item.type === 'file' && (
              <FormFile
                control={control}
                name={item.key}
                exactId={getId(item.key)}
                validation={{
                  required: item.isRequired,
                  validate: (value) => {
                    if (!item.isRequired) {
                      return true
                    }
                    if (typeof value === 'object') {
                      const fileSizeBytes = Array.from(value).reduce((acc, i) => {
                        return acc + i.size
                      }, 0)
                      let fileSizeMB = fileSizeBytes / (1024 ** 2)
                      return fileSizeMB < 25 ? true : t('errorFileSize')
                    }
                    return false
                  }
                }}
                errors={errors}
                label={item.placeholder}
                accept={item.fileTypes.join()}
              />
            )}

            {item.type === 'phone' && (
              <FormInputMask
                control={control}
                name={item.key}
                exactId={getId(item.key)}
                validation={{
                  required: item.isRequired,
                  validate: (value) => {
                    if (!item.isRequired) {
                      return true
                    }
                    if (value && value.replace(/\D/g, '').length >= 12) {
                      return true
                    }
                    return false
                  }
                }}
                errors={errors}
                placeholder={item.placeholder}
              />
            )}
          </div>
        ))}
      </div>

      <div
        className={classNames(
          styles['form-builder_bottom'],
          'd-f ai-c'
        )}
      >
        <Button
          className={styles['form-builder_submit']}
          isLoading={isSubmitting}
        >{submitLabel}</Button>

        {submitHint && (
          <div className={styles['form-builder_footnote-wr']}>
            <div
              className={styles['form-builder_footnote']}
              dangerouslySetInnerHTML={{ __html: changeImageSrc(submitHint) }}
            />

            <CSSTransition
              in={isShowSuccess}
              timeout={300}
              unmountOnExit
              classNames="fade"
            >
              <div
                className={classNames(
                  styles['form-builder_success'],
                  'd-f ai-c'
                )}
              >
                <Icon
                  name={'check-circle'}
                  width={20}
                  height={20}
                  viewBox="0 0 20 20"
                />
                <span>{t('successMsg')}</span>
              </div>
            </CSSTransition>
          </div>
        )}

        {!submitHint && (
          <CSSTransition
            in={isShowSuccess}
            timeout={300}
            unmountOnExit
            classNames="fade"
          >
            <div
              className={classNames(
                styles['form-builder_success'],
                'd-f ai-c'
              )}
            >
              <Icon
                name={'check-circle'}
                width={20}
                height={20}
                viewBox="0 0 20 20"
              />
              <span>{t('successMsg')}</span>
            </div>
          </CSSTransition>
        )}
      </div>
    </form>
  )
}

FormBuilder.propTypes = {
  form: PropTypes.object,
  className: PropTypes.string,
  inputsGap: PropTypes.oneOf(['gap_12px']),
  isSubmitFullWidth: PropTypes.bool,
  redirect: PropTypes.string,
  handleAfterSubmit: PropTypes.func,
}
