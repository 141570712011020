import { clientGraphQLRequest } from "@/app/api/apiClient"
import { getActiveLang } from '@/app/utils/getActiveLang'

import { QueryVacancies, QueryVacancy } from './query'

const PAGE_SIZE = 100

export const getAllVacancies = async (lang = 'ua', region, rubric) => {
  const local = getActiveLang(lang)
  let _allVacancies = []
  let rubricIds = []
  let query = `lang=${lang}&pageSize=${PAGE_SIZE}`
  const filters = {}
  if (region?.value) {
    query += `&regionId=${region.value}`
    filters.city = {
      name: {
        eqi: region.label
      }
    }
  }
  if (rubric?.value) {
    query += `&rubricId=${rubric.value}`
    filters.rubricId = {
      eq: rubric.value
    }
  }

  const [resVacanciesRobota, retVacanciesStrapi] = await Promise.all([
    fetch(
      `/api/vacancies/robota/vacancies?${query}`
    ),
    getVacancies({
      lang: local,
      pagination: {
        page: 1,
        pageSize: PAGE_SIZE
      },
      filters
    })
  ])

  // get vacancies from robota ua
  const getVacanciesRobota = await resVacanciesRobota.json()
  if (Array.isArray(getVacanciesRobota?.vacancies)) {
    _allVacancies = [...getVacanciesRobota.vacancies]
    rubricIds = [...getVacanciesRobota.rubricIds]
    // get all vacancies from robota
    const loopsAmount = Math.ceil(getVacanciesRobota?.pagination?.total / PAGE_SIZE)
    const loopsArr = Array.from(Array(loopsAmount).keys())
    if (loopsArr.length > 1) {
      const promises = []
      for (const i of loopsArr) {
        if (i !== 0) {
          promises.push(
            fetch(`/api/vacancies/robota/vacancies?start=${i * PAGE_SIZE}&${query}`)
          )
        }
      }
      const arrPromiseRes = await Promise.all(promises)
      const arrPromiseBody = await Promise.all(arrPromiseRes.map(r => r.json()))
      if (Array.isArray(arrPromiseBody) && arrPromiseBody.length) {
        arrPromiseBody.forEach((item) => {
          if (item.vacancies.length) {
            _allVacancies = [..._allVacancies, ...item.vacancies]
          }
        })
      }
    }
  }

  // get vacancies from strapi
  if (Array.isArray(retVacanciesStrapi?.vacancies)) {
    _allVacancies = [...retVacanciesStrapi.vacancies, ..._allVacancies]
    // get all vacancies from strapi
    const loopsAmountStrapi = Math.ceil(retVacanciesStrapi?.pagination?.total / PAGE_SIZE)
    const loopsArrStrapi = Array.from(Array(loopsAmountStrapi).keys())
    if (loopsArrStrapi.length > 1) {
      const promises = []
      for (const i of loopsArrStrapi) {
        if (i !== 0) {
          promises.push(
            getVacancies({
              lang: local,
              pagination: {
                page: i + 1,
                pageSize: PAGE_SIZE
              },
              filters
            })
          )
        }
      }
      const arrPromiseRes = await Promise.all(promises)
      if (Array.isArray(arrPromiseRes) && arrPromiseRes.length) {
        arrPromiseRes.forEach((item) => {
          if (item.vacancies.length) {
            _allVacancies = [..._allVacancies, ...item.vacancies]
          }
        })
      }
    }
  }

  _allVacancies.filter(item => {
    return item.rubricId && !rubricIds.includes(item.rubricId)
  }).forEach((item) => {
    rubricIds.push(item.rubricId)
  })

  return {
    vacancies: _allVacancies,
    total: _allVacancies.length,
    rubricIds
  }
}

async function getVacancies({ lang = 'uk', pagination, filters }) {
  try {
    const variables = {
      locale: lang,
      pagination,
      filters
    }
    const res = await clientGraphQLRequest(QueryVacancies, variables)
    if (Array.isArray(res?.vacancies?.data)) {
      return {
        vacancies: res.vacancies.data.map((vacancy) => ({
          id: vacancy?.id,
          name: vacancy?.attributes?.name,
          rubricId: vacancy?.attributes?.rubricId,
          natureOfWork: vacancy?.attributes?.natureOfWork,
          date: vacancy?.attributes?.updatedAt,
          cityName: vacancy?.attributes?.city?.data?.attributes?.name,
          isStrapi: true
        })),
        pagination: {
          total: res?.vacancies?.meta?.pagination?.total,
          pageSize: res?.vacancies?.meta?.pagination?.pageSize
        }
      }
    }
    return {}
  } catch (error) {
    console.warn(error, '-> getVacancies')
    throw error
  }
}

export const getVacancy = async (lang = 'ua', id, isStrapi) => {
  if (!id) {
    return
  }
  try {
    if (isStrapi) {
      const local = getActiveLang(lang)
      const variables = {
        locale: local,
        id
      }
      const res = await clientGraphQLRequest(QueryVacancy, variables)
      const data = res?.vacancy?.data?.attributes
      if (data) {
        return {
          id: res?.vacancy?.id,
          name: data.name,
          description: data.description,
          contactPerson: data.ContactPerson,
          contactPhone: data.ContactPhone,
          contactEmail: data.AdditionalContact,
          date: data.updatedAt,
          natureOfWork: data.natureOfWork, //  гібридна - 8, в офісі/на місці - 9, віддалена робота - 3
          scheduleId: data.scheduleId, // повна - 1, часткова - 2, проектна - 5, позмінна - 7
          cityName: data?.city?.data?.attributes?.name
        }
      }
    }
    const res = await fetch(`/api/vacancies/robota/vacancy?id=${id}`)
    const resBody = await res.json()
    if (resBody) {
      return resBody
    }
    return {}
  } catch (error) {
    console.warn(error, '-> getVacancy')
    throw error
  }
}
