'use client'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { changeImageSrc } from '@/app/utils/changeImageSrc'

import styles from './RichText.module.scss'

export default function RichText({
  text,
  attributes,
}) {

  return (
    <div
      className={classNames(
        styles['rich-text_wrapper'],
        `${attributes?.class || ''}`
      )}
      id={attributes?.id}
    >
      <div className='wr'>
        <div
          itemProp='description'
          className='rich-text'
          dangerouslySetInnerHTML={{ __html: changeImageSrc(text)  }}
        />
      </div>
    </div>
  )
}

RichText.propTypes = {
  text: PropTypes.string,
  attributes: PropTypes.shape({
    class: PropTypes.string,
    id: PropTypes.string
  })
}
